import {Button, CircularProgress, Grid, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {CheckNumberResponse} from "./CheckDocumentData";
import {ePismoClientInstance} from "../private/Service/EPismoClient";
import {useNavigate} from "react-router-dom";

export default function CheckDocumentResult(props: {signature:string}) {

    const [loading, setLoading] = useState(false)
    const [result, setResult] = useState<CheckNumberResponse | null>(null)
    const navigate = useNavigate()

    useEffect(() => {
        if(props.signature) {
            setLoading(true)
            ePismoClientInstance.getPublic("/api/v1/gui/documents/" + props.signature).then((data) => {
                setResult(data.data)
            }).finally(() => setLoading(false))
        }
    }, [props.signature]);
    return(
        <Grid container alignItems="center" justifyContent="center">
            <Grid xs={12} >
                <Typography align="left" variant="h1">Wynik weryfikacji: <span>{props.signature}</span></Typography>
            </Grid>
            {loading && <CircularProgress/>}
            {result && !result.validated && <Grid xs={12} md={6}><img src={require("./img/false.png")}/></Grid>}
            {result && result.validated && <Grid xs={12} md={6}><img src={require("./img/positive.png")}/></Grid>}
            {result && result.validated && <Grid xs={12} md={6} className="description">
                <b>Numer sygnatury: </b> {result.number}<br/>
                <b>Data dokumentu: </b> {result.date}<br/><br/>
                <b>Nadawca: </b><br/>
                {result.from.name}<br/>
                {result.from.address}<br/><br/>
                <b>Odbiorca: </b><br/>
                {result.to.name}<br/>
                {result.to.address}<br/>
                <div style={{textAlign: "center", marginTop: 20}}><Button variant="contained" onClick={e => {navigate('/sprawdz-pismo');window.location.reload()}}>Sprawdź kolejne pismo</Button></div>
            </Grid>}
        </Grid>
    )
}