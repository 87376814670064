import React, {useEffect, useState} from 'react';
import './App.css';
import {BeatLoader} from "react-spinners";
import AppLayout from "./layout/AppLayout";
import {useKeycloak} from "@react-keycloak/web";

function App() {
    const { keycloak, initialized } = useKeycloak();

    return (
    <div className="App">
      <div className="AppWrapper">

                {!initialized &&  <div className="SpinnerContainer"><img src={require('./img/epismo-logo.png')} alt="Logo e-pismo" /><BeatLoader color="#0077C9"/></div>}
                {initialized && <AppLayout/>}

      </div>
    </div>
    );
}

export default App;
