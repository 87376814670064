import {EventHandler} from "react";
import {Typography} from "@mui/material";

function AppHeaderButton(props: {icon: string, name: string, action: EventHandler<any>}) {
    return (
        <div className="AppHeaderButton" onClick={props.action}>
            <img src={props.icon} alt={props.name}/>
            <Typography>{props.name}</Typography>
        </div>
    )
}

export default AppHeaderButton;