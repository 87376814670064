import React from "react";
import {KeycloakProfile} from "keycloak-js";

export interface EkuriaKeycloakProfile extends KeycloakProfile {
    attributes?: any,
    username: string,
    email: string,
}

export const initialProfile : EkuriaKeycloakProfile = {
    username: "trwa ładowanie..",
    email: "trwa ładowanie",
    attributes: {
        displayed_name: "Trwa ładowanie",
        displayed_address: "Trwa ładowanie",
        responsible_person: "Trwa ładowanie",
        phone_number: "Trwa ładowanie",
    }
}

export const ProfileContext = React.createContext(initialProfile);
