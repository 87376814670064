import {Box} from "@mui/material";
import './CreateDocument.scss';
import React, {useState} from "react";
import CreateDocumentStep1 from "./CreateDocumentStep1";
import CreateDocumentStep2 from "./CreateDocumentStep2";
import CreateDocumentStep3 from "./CreateDocumentStep3";

export default function CreateDocument() {

    const [step, setStep] = useState(1)
    const [documentTypeCode, setDocumentTypeCode] = useState<Number>(0)
    const [signature, setSignature] = useState("")

    let step1Callback = (code : Number) => {
        if(code != null) {
            setDocumentTypeCode(code);
            setStep(2);
        }
    }

    let step2Callback = (signature : string) => {
        if(signature != null) {
            setSignature(signature);
            setStep(3);
        }
    }

    return (
        <Box className="GenerateDocument">
            {step == 1 && <CreateDocumentStep1 callback={step1Callback}/>}
            {step == 2 && <CreateDocumentStep2 documentTypeId={documentTypeCode} callback={step2Callback}/> }
            {step == 3 && <CreateDocumentStep3 signature={signature}/> }
        </Box>
    )
}