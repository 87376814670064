import AppHeader from "../private/Header/AppHeader";
import {Button, Grid, Typography} from "@mui/material";
import './WelcomePage.scss'
import {Link, useNavigate} from "react-router-dom";
import {useKeycloak} from "@react-keycloak/web";

export default function PublicWelcomePage () {

    const {keycloak, initialized} = useKeycloak();
    const navigate = useNavigate();

    return (
        <Grid container className="Home" sx={{p:4}}>
            <Grid xs={12} sx={{pt:2,mb:10}}><img src={require('../../img/epismo-logo-big.png')}/></Grid>
            <Grid md={5} className="Home-left">
                <div className="Home-left-wrapper">
                    <ul>
                        <li><span>Masz 100% pewność, że otrzymane informacje są wiarygodne.</span></li>
                        <li><span>Oszczędzasz swój czas i pieniądze, a zwiększasz swoją skuteczność duszpasterską.</span></li>
                        <li><span>Nie ma możliwości podrobienia pisma, pieczątki czy podpisu.</span></li>
                    </ul>
                </div>
            </Grid>
            <Grid md={7} sx={{p:5, pt:0, pb:0}} className="Home-right">
                <Typography variant="body1"><b>ePismo.app to wewnątrzkościelny system gwarantujący poufność przesyłanych informacji.</b></Typography>
                <Typography variant="body1"><b>Nikt nie gromadzi, nie przetwarza przesyłanych danych.</b></Typography>
                <Typography variant="body1" className="last"><b>W archiwum zostaje tylko sygnatura pisma.</b></Typography>
                <Typography variant="body2"><b>Wiarygodność pisma można sprawdzić na 3 sposoby:</b></Typography>
                <ul>
                    <li>Wczytując plik .pdf</li>
                    <li>Wpisując w systemie ePismo.app sygnaturę</li>
                    <li>Włączając swój aparat telefonu komórkowego i skanując kod qr</li>
                </ul>
                <Grid container sx={{mt:3}}>
                    <Grid md={5}>
                        <Button fullWidth variant="contained" onClick={e => keycloak.login()}>Zaloguj</Button>
                    </Grid>
                    <Grid md={7}>
                        <Link to="/sprawdz-subskrypcje">Sprawdź koszt subskrypcji</Link>
                    </Grid>
                    <Grid xs={12}>
                        <Button fullWidth variant="contained" onClick={e => navigate('/sprawdz-pismo')}>Sprawdź sygnaturę pisma bez zakładania konta</Button>

                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}