import React, {useState} from "react";
import {Alert, Box, Button, CircularProgress, TextField, Typography} from "@mui/material";
import {useKeycloak} from "@react-keycloak/web";
import {ePismoClientInstance} from "../Service/EPismoClient";

export default function CreateDocumentStep3(props: {signature: string}) {

    const keycloak = useKeycloak();
    const [email, setEmail] = useState("")
    const [sending, setSending] = useState(false)
    const [manualEmail, setManualEmail] = useState(false)
    const [sent, setSent] = useState(false)
    const [errorSent, setErrorSent] = useState(false)

    const downloadFile = (e: any) => {
        ePismoClientInstance.getFile('/api/v1/gui/documents/' + props.signature + "/pdf", keycloak)
            .then(response => {
                const href = URL.createObjectURL(response.data);

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', props.signature + '.pdf'); //or any other extension
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            })
    }

    const send = () => {
        setSending(true)
        let url = '/api/v1/gui/documents/' + props.signature + '/email';
        if(email) {
            url += '?email=' + email
        }
        ePismoClientInstance.post(url, keycloak, null).then(resp => {
            let data = resp.data
            if(data.status === 'OK') {
                setSent(true)
            } else if (data.status === 'NO_EMAIL') {
                setManualEmail(true)
            } else {
                setErrorSent(true)
            }
            setSending(false)
        }).catch(e => setErrorSent(true))
    }

    return (
        <Box className="Step3">
            <Typography align="left" variant="h1"><span>3/3</span> Sukces</Typography>
            <Box className="SignatureWrapper">
                <Typography variant="h3">Pismo uzyskało sygnaturę nr</Typography>
                <Typography variant="h4">{props.signature}</Typography>
                <Button size="large" variant="contained" onClick={downloadFile}>Pobierz pismo</Button>
                <Button size="large" variant="contained" onClick={send} disabled={sending || manualEmail || sent}>Wyślij pismo {sending && <CircularProgress size={20} sx={{ml: 1}} />}</Button>
                {manualEmail && <div className="sendEmail" >
                    <Typography>Nie znaleziono adresu e-mail dla odbiorcy. Aby wysłać wiadomość, proszę uzupełnić adres w polu niżej.</Typography>
                    <TextField size="small" style={{width: 'auto', marginRight: '15px'}} label="Adres e-mail" type="email" onChange={e => setEmail(e.target.value)}/>
                    <Button size="large" variant="contained" onClick={send} disabled={sending || sent}>Wyślij pismo {sending && <CircularProgress size={20} sx={{ml: 1}} />}</Button>
                </div>}
                <span className="emailMessages">
                    {sent && <Alert severity="success">Wysłano dokument wiadomośćią e-mail</Alert>}
                    {errorSent && <Alert severity="error">Bład podczas wysyłania wiadomości. Proszę pobrać dokument i samodzielnie go wysłać.</Alert>}
                </span>
                <Typography>Zawsze możesz wrócić do dokumentu. Podpisany plik znajduje się w zakładce Pisma. Będzie można go pobrać przez najbliższe 7 dni.</Typography>
            </Box>
        </Box>
    )
}