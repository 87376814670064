import './AppLayout.scss';
import {ProfileContext, initialProfile} from "../context/ProfileContext";
import React, {useEffect, useState} from "react";
import {useKeycloak} from "@react-keycloak/web";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import PublicWelcomePage from "./WelcomePage/PublicWelcomePage";
import PrivateLayout from "./private/PrivateLayout";
import {Typography} from "@mui/material";
import CreateDocument from "./private/CreateDocument/CreateDocument";
import CheckDocument from "./CheckDocument/CheckDocument";
import PublicLayout from "./public/PublicLayout";
import ProfileView from "./private/Profile/ProfileView";
import DocumentList from "./private/DocumentList/DocumentList";
import SubscriptionView from "./private/Subscription/SubscriptionView";
import BuySubscriptionView from "./private/Subscription/BuySubscriptionView";

function AppLayout() {

    const [profile, setProfile] = useState(initialProfile);
    const {keycloak, initialized} = useKeycloak();
    useEffect(() => {
        if (initialized && keycloak.authenticated) {
            keycloak.loadUserProfile().then(p => {
                setProfile(p as any);
            })
        }
    }, [initialized])

    return (
        <ProfileContext.Provider value={profile}>
            <div className="AppLayout">
                <BrowserRouter>
                    <Routes>
                        <Route path="/">
                            <Route index element={
                                <div>
                                    {!keycloak.authenticated && <PublicWelcomePage/>}
                                    {keycloak.authenticated && <PrivateLayout element={<ProfileView/>}/>}
                                </div>
                            }/>
                            <Route path="nadaj-pismo" element={
                                <div>
                                    {!keycloak.authenticated && <PublicWelcomePage/>}
                                    {keycloak.authenticated && <PrivateLayout element={<CreateDocument/>}/>}
                                </div>
                            }/>
                            <Route path="sprawdz-pismo" element={
                                <div>
                                    {!keycloak.authenticated && <PublicLayout element={<CheckDocument/>}/>}
                                    {keycloak.authenticated && <PrivateLayout element={<CheckDocument/>}/>}
                                </div>
                            }/>
                            <Route path="lista-pism" element={
                                <div>
                                    {!keycloak.authenticated && <PublicWelcomePage/>}
                                    {keycloak.authenticated && <PrivateLayout element={<DocumentList/>}/>}
                                </div>
                            }/>
                            <Route path="subskrypcje" element={
                                <div>
                                    {!keycloak.authenticated && <PublicLayout element={<PublicWelcomePage/>}/>}
                                    {keycloak.authenticated && <PrivateLayout element={<SubscriptionView/>}/>}
                                </div>
                            }/>
                            <Route path="sprawdz-subskrypcje" element={
                                <div>
                                    {!keycloak.authenticated && <PublicLayout element={<BuySubscriptionView/>}/>}
                                    {keycloak.authenticated && <PrivateLayout element={<BuySubscriptionView/>}/>}
                                </div>
                            } />
                        </Route>
                    </Routes>
                </BrowserRouter>
            </div>
        </ProfileContext.Provider>
    )
}

export default AppLayout;