import {Box, Button, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Subscription} from "../CreateDocument/DocumentData";
import {ePismoClientInstance} from "../Service/EPismoClient";
import {useKeycloak} from "@react-keycloak/web";
import {BeatLoader} from "react-spinners";
import './SubscriptionView.scss';
import {useNavigate} from "react-router-dom";
export default function SubscriptionView() {
    const [loading, setLoading] = useState(false)
    const [subscription, setSubscription] = useState({documentsLimit: 0, remainsDocumentsinMonth: 0} as Subscription)

    const keycloak = useKeycloak();
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true)
        ePismoClientInstance.get("/api/v1/gui/subscription", keycloak).then(resp => {
            setSubscription(resp.data)
            setLoading(false)
        })

    }, []);

    function default_string(s: String) {
        if(s)
            return s
        else
            return "Brak"
    }

    return (
        <Box className="SubscriptionData">
            <Typography variant="h3" sx={{mb: 4}}>Subskrypcja</Typography>
            {loading && <BeatLoader color="#0077C9"/>}
            {!loading && <>
                <Box sx={{mb: 2}}>
                    <Typography><b>Aktualny plan</b></Typography>
                    <Typography>{default_string(subscription.subscriptionName)}</Typography>
                </Box>
                <Box sx={{mb: 2}}>
                    <Typography><b>Aktywna od</b></Typography>
                    <Typography>{default_string(subscription.activeFrom)}</Typography>
                </Box>
                <Box sx={{mb: 2}}>
                    <Typography><b>Aktywna do</b></Typography>
                    <Typography>{default_string(subscription.activeTo)}</Typography>
                </Box>
                <Box sx={{mb: 2}}>
                    <Typography><b>Miesięczny limit dokumentów</b></Typography>
                    <Typography>{subscription.documentsLimit}</Typography>
                </Box>
                <Box sx={{mb: 2}}>
                    <Typography><b>Ilość pozostałych dokumentów w bieżącym miesiącu</b></Typography>
                    <Typography>{subscription.remainsDocumentsinMonth}</Typography>
                </Box>
                <div style={{textAlign: "left"}}><Button variant="contained" onClick={e => navigate("/sprawdz-subskrypcje")}>Wykup subskrypcję</Button></div>
            </>}

        </Box>
    )
}