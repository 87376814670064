import {Box, Grid, Typography} from "@mui/material";
import './PublicLayout.scss';
import {Link, NavLink} from "react-router-dom";
import {ReactNode} from "react";
import AppHeader from "./Header/AppHeader";

export default function PublicLayout(props: {element: ReactNode}) {
    return (
        <div>
            <AppHeader/>
            <Grid container className="privateWrapper" alignItems="center" justifyContent="center">

                <Grid xs={12} md={8}>
                    <Box className="privateContentWrapper">
                        {props.element}
                    </Box>
                </Grid>
            </Grid>
        </div>
    )
}
