import {Alert, Box, Grid, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {initDocumentTypes, Subscription} from "./DocumentData";
import {ePismoClientInstance} from "../Service/EPismoClient";
import {useKeycloak} from "@react-keycloak/web";
import {BeatLoader} from "react-spinners";

export default function CreateDocumentStep1(props : {callback: any}) {
    const [documentTypes, setDocumentTypes] = useState(initDocumentTypes)
    const [loading, setLoading] = useState(false)
    const [subscription, setSubscription] = useState({documentsLimit: 0, remainsDocumentsinMonth: 0} as Subscription)
    const keycloak = useKeycloak();
    useEffect(() => {
        setLoading(true)
        ePismoClientInstance.get("/api/v1/gui/subscription", keycloak).then(resp => setSubscription(resp.data))
        ePismoClientInstance.get("/api/v1/gui/documents/types", keycloak).then(resp => {
            setDocumentTypes(resp.data)
            setLoading(false)
        })
    }, []);

    function sendingDocumentIsAllowed() {
        return subscription.documentsLimit > 0 && subscription.remainsDocumentsinMonth > 0;
    }

    return (
        <Box className="Step1">
            <Grid container spacing={3}>
                <Grid xs={12}>
                    <Typography align="left" variant="h1"><span>1/3</span> Wybierz rodzaj pisma</Typography>
                </Grid>
                {loading && <BeatLoader color="#0077C9"/>}
                {(!loading && !sendingDocumentIsAllowed()) && <Alert severity="error">Brak wykupionej subskrypcji lub limit miesięczny został wyczerpany</Alert>}
                {(!loading && sendingDocumentIsAllowed()) && documentTypes.map(t =>
                    <Grid xs={12} md={4} className="documentTypeWrapper" onClick={e => props.callback(t.code)}>
                        <div className="documentType">
                            <div><img src={require('./icons/icon-document.png')} /></div>
                            <Typography>{t.displayedName}</Typography>
                        </div>
                    </Grid>
                )}

            </Grid>
        </Box>
    )
}